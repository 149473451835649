require('./aos');
require('./countup');
require('./dropdown');
require('./isotope');
require('./lazysizes.min');
require('./modal');
require('./navbar');
require('./polyfills');
require('./popovers');
require('./pricing');
require('./smooth-scroll');
require('./tooltips');
require('./typed');
