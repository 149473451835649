//
// popovers.js
// Theme module
//

'use strict';

(function() {

  //
  // Variables
  //

  var toggle = document.querySelectorAll('[data-toggle="popover"]');


  //
  // Functions
  //

  function init(toggle) {
    $(toggle).popover({
     trigger: 'focus'
   });
  }


  //
  // Events
  //

  if (toggle) {
    init(toggle);
  }
  
})();